<template>
  <div class="m-5 pb-5">
    <h4>{{ name }}</h4>
    <p>{{ description }}</p>
    <div>
      <b-img
        class="mb-2"
        style="width: 70%;"
        v-if="imageUrl"
        :src="imageUrl"
        fluid
        alt="Fluid image"
      ></b-img>
    </div>
    <b-button
      class="mr-2 ml-0 green-button"
      @click="$emit('select-template')"
    >
      <b-icon
        icon="plus-circle-fill"
        class="mr-2"
        aria-hidden="true"
        scale="1"
      ></b-icon>
      {{ $t('webmag.select') }}
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'ModalContentShowFormPreview',
  props: {
    name: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    imageUrl: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    showAcknowledgeModal: false,
    showPreviewModal: false,
    iframeSourceForPreview: null,
    previewActionObject: null,
    itemName: null,
  }),
  methods: {
    openSharedTemplateInPreview(item) {
      this.iframeSourceForPreview = item.iframeSourceForPreview;
      this.previewActionObject = item.previewActionObject;
      this.itemName = item.itemName;
      this.showPreviewModal = true;
    },
    previewModalClosed() {
      this.showPreviewModal = false;
    },
  },
};
</script>
