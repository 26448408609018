var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "m-5 pb-5" },
    [
      _c("h4", [_vm._v(_vm._s(_vm.name))]),
      _c("p", [_vm._v(_vm._s(_vm.description))]),
      _c(
        "div",
        [
          _vm.imageUrl
            ? _c("b-img", {
                staticClass: "mb-2",
                staticStyle: { width: "70%" },
                attrs: { src: _vm.imageUrl, fluid: "", alt: "Fluid image" }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "b-button",
        {
          staticClass: "mr-2 ml-0 green-button",
          on: {
            click: function($event) {
              return _vm.$emit("select-template")
            }
          }
        },
        [
          _c("b-icon", {
            staticClass: "mr-2",
            attrs: {
              icon: "plus-circle-fill",
              "aria-hidden": "true",
              scale: "1"
            }
          }),
          _vm._v(" " + _vm._s(_vm.$t("webmag.select")) + " ")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }