var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "4" } }, [
            _c("label", { attrs: { for: "value-name" } }, [
              _vm._v(" " + _vm._s(_vm.$t("modals.createContext.value")) + " ")
            ])
          ]),
          _c("b-col", { attrs: { cols: "8" } }, [
            _c(
              "div",
              { staticClass: "mt-3" },
              [
                _c("b-form-input", {
                  attrs: { debounce: "500" },
                  on: {
                    update: _vm.updateValues,
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.createUpdateEntry.apply(null, arguments)
                    }
                  },
                  model: {
                    value: _vm.leftValue,
                    callback: function($$v) {
                      _vm.leftValue = $$v
                    },
                    expression: "leftValue"
                  }
                }),
                _c("b-form-text", [
                  _vm._v(
                    " " + _vm._s(_vm.$t("modals.createContext.valueDesc")) + " "
                  )
                ])
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "4" } }, [
            _c("label", { attrs: { for: "value-name" } }, [
              _vm._v(
                " " + _vm._s(_vm.$t("modals.createContext.operator")) + " "
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "8" } },
            [
              _c(
                "div",
                { staticClass: "d-inline-block" },
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.operatorOptions },
                    on: { change: _vm.updateValues },
                    model: {
                      value: _vm.operator,
                      callback: function($$v) {
                        _vm.operator = $$v
                      },
                      expression: "operator"
                    }
                  })
                ],
                1
              ),
              _c("b-form-text", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("modals.createContext.operatorDesc")) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      !_vm.usePersonalizationSetAsContext
        ? _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "4" } }, [
                _c("label", { attrs: { for: "value-name" } }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("modals.createContext.rightValue")) +
                      " "
                  )
                ])
              ]),
              _c(
                "b-col",
                { attrs: { cols: "8" } },
                [
                  _c("b-form-input", {
                    attrs: { id: "entry-name", debounce: "500" },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.createUpdateEntry.apply(null, arguments)
                      },
                      update: _vm.updateValues
                    },
                    model: {
                      value: _vm.rightValue,
                      callback: function($$v) {
                        _vm.rightValue = $$v
                      },
                      expression: "rightValue"
                    }
                  }),
                  _c("b-form-text", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("modals.createContext.rightValueDesc")) +
                        " "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }